<template>
    <div class="header login-modal">
        <div
            id="mobMEnu"
            class="mobMenu float-panel"
            data-top="0"
            data-scroll="200"
        >
            <div class="mh-head first mh-align-left Sticky">
                <div class="mob-num">
                    Trade Enquiry : <a href="tel:04872963603">0487-2963603</a>
                    <div class="clear"></div>
                </div>
                <div class="mob-num-respsv">
                    <div id="marquee">
                        <marquee
                            onmouseover="this.stop();"
                            onmouseout="this.start();"
                            scrollamount="3"
                        >
              <span
              >Trade Enquiry :
                <a href="tel:04872963603">0487-2963603</a></span
              >

                            <span class="ml-4">
                Trade Enquiry : <a href="tel:04872963603">0487-2963603</a></span
                            >

                            <span class="ml-4"
                            >Trade Enquiry :
                <a href="tel:04872963603">0487-2963603</a></span
                            >
                        </marquee>
                    </div>
                </div>

                <slot name="button"></slot>

            </div>

        </div>

        <header
            class="headerWrapp float-panel"
            :class="addClass"
            data-top="0"
            data-scroll="200"
        >
            <div class="container-fluid px-lg-5">
                <figure class="suvudi-logo" v-if="!OpenMenu">
                    <img
                        src="../assets/images/suvidi-logo-new.png"
                        alt="Suvidi Organic Brown Rice"
                    />
                </figure>
                <div class="menuCall">
                    <a href="tel:+91 90726 03803">+91 90726 03803</a>
                </div>
                <div class="clear"></div>
                <div class="primary-menu">
                    <div class="align">
                        <div id="smoothmenu1" class="ddsmoothmenu">
                            <ul>
                                <li>
                                    <router-link to="/home" active-class="active">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/our-story" active-class="active">Our Story</router-link>
                                </li>
                                <li>
                                    <router-link to="/products" active-class="active">Products</router-link>
                                </li>
                                <li>
                                    <router-link to="/why-organic" active-class="active">Why Suvidi</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div class="secondary-menu">
                    <div class="align">
                        <div id="smoothmenu2" class="ddsmoothmenu">
                            <ul>
                                <!--                <li>-->
                                <!--                  <router-link to="/farmer-stories" active-class="active">Stories</router-link>-->
                                <!--                </li>-->
                                <li>
                                    <router-link to="/farmer-stories" active-class="active">Farmer Stories</router-link>
                                </li>
                                <li>
                                    <router-link to="/our-stores" active-class="active">Our Shop</router-link>
                                </li>
                                <li>
                                    <router-link to="/contact" active-class="active">Contact Us</router-link>
                                </li>
                                <li>
                                    <router-link to="/Cart" class="cart-menu" active-class="active">
                                        <div class="position-relative">
                                            <img src="../assets/images/shopping-cart.png" class="img-fluid" alt="#">
                                            <!--                                            <span class="cart-item-no">2</span>-->
                                        </div>
                                    </router-link>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" class="cart-menu" active-class="active"><img
                                        src="../assets/images/man-user.png" class="img-fluid" alt="#"></a>
                                    <ul class="submenu-pos">
                                        <li v-if="currentUser.username && currentUser.superuser === false">
                                            <router-link to="/myprofile">Profile</router-link>
                                        </li>
                                        <li v-if="currentUser.username && currentUser.superuser === false">
                                            <router-link to="/myorders">My Orders</router-link>
                                        </li>
                                        <li v-if="currentUser.username && currentUser.superuser === false">
                                            <a href="#" @click="logOut">Logout</a>
                                        </li>
                                        <li v-else><a v-b-modal.modal-center>Login</a></li>
                                    </ul>
                                </li>
                                <!--<li class="menuCall"><a href="tel:+91 90726 03803">+91 90726 03803</a></li>-->
                            </ul>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </header>
        <!-- end header wrapp -->

        <b-modal class="loginModal" id="modal-center" no-close-on-backdrop hide-footer centered title="LOGIN">
            <Signinmodal @success="logined"></Signinmodal>
        </b-modal>
    </div>
</template>

<script>
import Signinmodal from '@components/Signinmodal';
import { mapGetters, mapActions } from 'vuex';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'Header',
    props: {
        msg: String
    },
    components: {
        Signinmodal
    },
    data: function () {
        return {
            scrollPosition: 0,
            pgurl: 'home',
            OpenMenu: false,
            isMobile1: false
        };
    },

    methods: {
        updateScroll () {
            this.scrollPosition = window.scrollY;
            this.$emit('scrollPosition', this.scrollPosition);
        },
        OpenMenu1 () {
            this.OpenMenu = true;

            alert(this.OpenMenu);
        },
        CloseMenu () {
            this.OpenMenu = false;
        },
        isMobile () {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                this.isMobile1 = true;
            } else {
                this.isMobile1 = false;
            }
        },
        logined () {
            this.$bvModal.hide('modal-center');
        },
        ...mapActions(['signOut']),
        logOut () {
            this.signOut();
            axios.get(urls.auth.logout);
            this.$router.push('/');
        }
    },
    computed: {
        ...mapGetters(['currentUser']),
        addClass () {
            this.isMobile();
            if (this.isMobile1 === true) {
                return 'fixed';
            } else if (this.scrollPosition > 200) {
                return 'fixed';
            } else {
                return 'default';
            }
        }
    },
    mounted () {
        window.addEventListener('scroll', this.updateScroll);
        this.pgurl = window.location.href.substr(
            window.location.href.lastIndexOf('/') + 1
        );
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.secondary-menu .ddsmoothmenu ul li a {
    font-size: 14px;
}

.modal .close {
    display: none !important;
}
</style>
