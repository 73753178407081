<template>
    <div class="sub-btm-bg">
        <div class="sub-btm-wrapp">
            <div class="container">
                <div class="btm-org-cap">
                    <h3>100%</h3>
                    <h4>Organic</h4>
                </div>
                <div class="subHmeDel">
                    <p>For Home Delivery
                        <br>
                        &amp; Trade Enquiries</p>
                    <a href="tel:04872963603">0487-2963603</a>
                    <div class="clear"></div>
                </div>
                <div class="org-cer"><img src="../assets/images/org-certificate-new.png"
                                          alt="Suvidi Organic Brown Rice"></div>
                <div class="clear"></div>
            </div>
        </div>
        <footer class="footerWrapp animatedParent" data-sequence='500'>
            <div class="footer-top">
                <div class="container">
                    <div class="footMenu  animated fadeInUpShort" data-aos="fade-up" data-aos-delay="1000" data-id='1'>
                        <ul>
                            <li>
                                <router-link to="/home">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/our-story">Our Story</router-link>
                            </li>
                            <li>
                                <router-link to="/products">Products</router-link>
                            </li>
                            <li>
                                <router-link to="/why-organic">Why Suvidi</router-link>
                            </li>
                            <li>
                                <router-link to="/farmer-stories">Farmer Stories</router-link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <router-link to="/our-stores">Our Shop</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Contact Us</router-link>
                            </li>
                            <li>
                                <router-link to="/sitemap">Site Map</router-link>
                            </li>
                            <li>
                                <router-link to="">Disclaimer</router-link>
                            </li>
                        </ul>
                        <div class="clear"></div>
                    </div>
                    <!-- foot menu -->
                    <div class="footContact">
                        <figure class=" animated fadeInUpShort" data-id='2' data-aos="fade-up" data-aos-delay="1200">
                            <img src="../assets/images/suvidi-foot-logo-new.png" alt="Suvidi Organic Brown Rice">
                        </figure>
                        <address class=" animated fadeInUpShort" data-aos="fade-up" data-aos-delay="1500" data-id='3'>
                            <p>PROCESSED FOR</p>
                            <p>CEDAR Retail Pvt. Ltd., <br>
                                at Sree Krishna Modern Rice Mill<br>
                                NSS College P.O., Nenmara<br>
                                Palakkad - 678 508
                                <br>
                                FSSAI: 11318009000136</p>
                        </address>
                        <address class="animated fadeInUpShort" data-aos="fade-up" data-aos-delay="2000" data-id='4'>
                            <p>PACKED AND MARKETED BY</p>
                            <p>CEDAR Retail Pvt. Ltd. <br>
                                7/7/732/9-11, Green Tower
                                <br>
                                Mannuthy - Nadathara Road <br>
                                Pattalakkunnu, Thrissur – 680 651
                                <br>
                                FSSAI: 11318008001403</p>
                        </address>
                        <div class="clear"></div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div><!-- end footer top -->
            <div class="container">
                <div class="footer-bottom " data-aos-delay="700" data-id='5'>
                    <div class="smo">
                        <ul>
                            <li class="fb"><a href="https://www.facebook.com/SuvidiOrganic/" target="_blank"></a></li>
                            <li class="insta"><a href="https://www.instagram.com/suvidiorganic/" target="_blank"></a>
                            </li>
                            <li class="twitt"><a href="https://www.linkedin.com/company/suvidiorganic"
                                                 target="_blank"></a></li>
                        </ul>
                        <div class="clear"></div>
                    </div>
                    <div class="cpr">&copy; 2021 suvidiorganic.com. All Rights Reserved.</div>
                    <!-- <div class="design">Design: <a href="http://metexcreations.com/" target="_blank">  metexcreations.com</a> </div> -->
                    <div class="clear"></div>
                </div>
            </div>
            <div class="clear"></div>
        </footer><!-- end footer wrapp -->

        <div id="backtop" v-on:click="scrollToTop()"
             v-bind:style=" scrollPosition>200 ? 'display: block' : 'display : none'" data-v-w="300, 1200">&#9650;
        </div>
        <router-link to="/our-stores" class="buy-float">
            <img src="../assets/images/cart-float.png"/>
            <p>Shop Now</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        msg: String
    },
    data: function () {
        return {
            scrollPosition: 0
        };
    },

    methods: {
        updateScroll () {
            this.scrollPosition = window.scrollY;
        },
        scrollToTop () {
            window.scrollTo({

                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }

    },

    mounted () {
        window.addEventListener('scroll', this.updateScroll);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.footerWrapp.animatedParent {
    background-image: none;
}
</style>
