<template>
    <div class="pr-3 pb-3 loginModal">
        <form id="signin" v-show="showLoginPage" autocomplete="off" role="form" @submit.prevent="sendOTP">
            <div class="alert alert-danger" v-if="errorMessage" v-html="errorMessage"></div>
            <div class="">
                <div class="fom-align">
                    <div class="wrap-input100 validate-input">
                        <span class="label-input100">Mobile : </span>
                        <input class="input100" type="text" maxlength="10" minlength="10"
                               @keypress="onlyNumberKey($event)" name="Phone"
                               placeholder="Enter your mobile number"
                               v-model="model1.phone"
                               required>
                        <span class="focus-input100"></span>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
            <div class="fom-align">
                <div class="validate-input">

                    <div style="font-size: 12px;">
                        <input class="" type="checkbox" v-model="terms">
                        I agree to the <a @click="openTermsAndCondition" class="terms">Terms and Condition</a>
                        <span class="focus-input100"></span>
                    </div>

                </div>
            </div>

            <div class="fom-field frmBtnFIeld" style="float:none; margin:0 auto; width:100%; clear:both">
                <div class="fom-align0  float-right">
                    <div class="container-contact100-form-btn" style="text-align:center; width:100%">
                        <input type="hidden">
                        <button class="contact100-form-btn" @click="countDownTimer" type="submit" style="margin: auto">
                              <span v-if="!loading">
                                Send OTP
                              </span>
                            <span v-else>
                                <span class="btn-loader mr-2"><SimpleLoader></SimpleLoader></span> Logging...
                              </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </form>
        <form id="otpVerification" v-show="showVerificationPage" autocomplete="off" role="form"
              @submit.prevent="verifyOTP">
            <div class="">
                <div class="fom-align">
                    <div class="wrap-input100 validate-input">
                        <span class="label-input100">OTP : </span>
                        <input class="input100" type="text" maxlength="6" minlength="6"
                               @keypress="onlyNumberKey($event)" name="Otp"
                               placeholder="Enter OTP"
                               v-model="model.otp"
                               required>
                        <span class="focus-input100"></span>
                    </div>
                    <span v-if="countDown!==0">Resend OTP in {{ countDown }} seconds.</span>
                    <b-btn v-if="countDown===0" @click="refreshTimer" variant="success" size="sm">Resend OTP</b-btn>
                    <div class="alert alert-danger" v-if="errorMessage1" v-html="errorMessage1"></div>
                </div>
            </div>
            <div class="clear"></div>

            <div class="fom-field frmBtnFIeld" style="float:none; margin:0 auto; width:100%; clear:both">
                <div class="fom-align0  float-right">
                    <div class="container-contact100-form-btn" style="text-align:center; width:100%">
                        <input type="hidden">
                        <button class="contact100-form-btn" type="submit" style="margin: auto">
                              <span v-if="!loading1">
                                Confirm
                              </span>
                            <span v-else>
                                <span class="btn-loader mr-2"><SimpleLoader></SimpleLoader></span> Logging...
                              </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </form>
        <form id="signup" autocomplete="off" v-show="showRegisterPage" role="form" @submit.prevent="registerUser">
            <div class="">
                <div class="fom-align">
                    <div class="wrap-input100 validate-input" data-validate="Name is required">
                        <span class="label-input100">Name:</span>
                        <input class="input100" type="text" name="Name" placeholder="Enter your name *"
                               v-model="model2.name"
                               required>
                        <span class="focus-input100"></span>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="fom-align">
                    <div class="wrap-input100 validate-input"
                         data-validate="Valid email is required: ex@abc.xyz">
                        <span class="label-input100">Email:</span>
                        <input class="input100" type="email" name="Email"
                               placeholder="Enter email address *"
                               v-model="model2.email" required>
                        <span class="focus-input100"></span>
                    </div>
                    <div class="alert alert-danger" v-if="errorMessage2" v-html="errorMessage2"></div>
                </div>
            </div>
            <div class="clear"></div>
            <div class="fom-field frmBtnFIeld" style="float:none; margin:0 auto; width:100%; clear:both">
                <div class="fom-align0 float-right">
                    <div class="container-contact100-form-btn" style="text-align:center; width:100%">
                        <input type="hidden">
                        <button class="contact100-form-btn" type="submit" style="margin:0 auto">
                                <span v-if="!loading2">
                                    Update
                                </span>
                            <span v-else>
                                   <span class="btn-loader mr-2"><SimpleLoader></SimpleLoader></span> Signing...
                                </span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="clear"></div>

        </form>
        <div class="text-center mb-4" v-show="successPage">Profile updated successfully.</div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import SimpleLoader from '@components/SimpleLoader';

export default {
    name: 'OTPLogin',
    components: { SimpleLoader },
    data () {
        return {
            countDown: 120,
            is_exist: '',
            loading: false,
            loading1: false,
            loading2: false,
            showLoginPage: true,
            showVerificationPage: false,
            showRegisterPage: false,
            successPage: false,
            errorMessage: '',
            errorMessage1: '',
            errorMessage2: '',
            model: {
                otp: '',
                phone: ''
            },
            model1: {
                phone: ''
            },
            model2: {
                id: '',
                name: '',
                email: ''
            },
            tacURL: '',
            terms: false
        };
    },
    beforeMount () {
        this.getTACurl();
    },
    methods: {
        ...mapActions(['setUser']),
        async getTACurl () {
            const response = await axios.get(urls.admin.masterData.termsAndCondition.fileUrl);
            // console.log('response =>', response);
            this.tacURL = response.data.tac;
            // console.log('this.tacURL - : ', this.tacURL);
        },
        async sendOTP () {
            if (!this.terms) {
                alert('Please check and accept Terms and Condition');
                return;
            }
            this.loading = true;
            const component = this;
            const response = await axios.form(urls.auth.sendOtp, component.model1);
            const json = response.data;
            if (json.error === false) {
                component.is_exist = json.is_exist;
                component.model2.id = json.id;
                component.loading = false;
            } else {
                component.errorMessage = json.errors.__all__;
                component.loading = false;
            }
            if (json.error === false && this.loading === false) {
                this.model.phone = this.model1.phone;
                this.showLoginPage = false;
                this.showVerificationPage = true;
            }
        },
        async resendOTP () {
            this.loading = true;
            const component = this;
            const response = await axios.form(urls.auth.resendOTP, component.model1);
            const json = response.data;
            if (json.error === false) {
                component.loading = false;
            } else {
                component.errorMessage = json.errors.__all__;
                component.loading = false;
            }
            if (json.error === false && this.loading === false) {
                this.model.phone = this.model1.phone;
                this.showLoginPage = false;
                this.showVerificationPage = true;
            }
        },
        async verifyOTP () {
            this.loading1 = true;
            const component = this;
            const response = await axios.form(urls.auth.validateOtp, component.model);
            const json = response.data;
            if (json.error === false) {
                if (component.is_exist === false) {
                    component.showVerificationPage = false;
                    component.showRegisterPage = true;
                } else {
                    location.reload();
                }
                component.loading1 = false;
            } else {
                component.errorMessage1 = json.errors.__all__;
                component.loading1 = false;
            }
        },
        registerUser () {
            this.loading2 = true;
            const component = this;
            axios.form(urls.auth.updateProfile, component.model2).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    component.showRegisterPage = false;
                    component.successPage = true;
                    location.reload();
                } else {
                    component.errorMessage2 = json.errors.__all__;
                }
                component.loading2 = false;
            }).catch(function () {
                component.loading2 = false;
            });
        },
        onlyNumberKey (evt) {
            evt = (evt) || window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        countDownTimer () {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
        refreshTimer () {
            this.countDown = 120;
            this.countDownTimer();
            this.resendOTP();
        },
        openTermsAndCondition () {
            window.open(this.tacURL);
        }

    }
}
;
</script>

<style>
.terms:hover {
    cursor: pointer;
}
.terms {
    color: #4924a4!important;
}
</style>
