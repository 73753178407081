<template>
    <div>
        <!--    <loading-animation v-if="loading"></loading-animation>-->
        <!--    <div v-else>-->
        <Sidenav @Clicked="sideNav"></Sidenav>
        <div :class="customClass" class="body-transition">
            <Header @scrollPosition="scrollPosition">
                <template #button>
          <span class="mh-btns-left"> <button id="navOpen" class="hamburger hamburger--collapse" @click="sideNav"><span
              class="hamburger-box"><span
              class="hamburger-inner"></span></span></button></span>
                </template>
            </Header>

            <transition name="fade" mode="out-in">
                <router-view/>
            </transition>

            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Sidenav from '@/components/Sidenav';

export default {
    name: 'base',
    components: { Sidenav, Footer, Header },
    data () {
        return {
            customClass: '',
            scrollPost: ''
        };
    },
    methods: {
        sideNav () {
            const element = document.getElementById('menu');
            const element1 = document.getElementById('mobMEnu');
            const element2 = document.getElementById('navOpen');

            if (this.customClass === 'body-push') {
                this.customClass = '';
                document.body.classList.remove('overflow-hidden');
                element.style.display = 'none';
                element1.style.top = '0px';
                element2.classList.remove('is-active');
            } else {
                this.customClass = 'body-push';
                document.body.classList.add('overflow-hidden');
                element.style.display = 'block';
                element1.style.top = this.scrollPost + 'px';
                element2.classList.add('is-active');
            }
        },
        scrollPosition (scroll) {
            this.scrollPost = scroll;
        }
    }
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
