<template>
    <nav id="menu" class="mm-menu mm-offcanvas mm-right" style="display: none">
        <div class="mm-panels">
            <div class="mm-panel mm-hasnavbar mm-opened" id="mm-1">
                <div class="mm-navbar"><a class="mm-title">Menu</a></div>
                <ul class="mm-listview">
                    <li @click="clicked">
                        <router-link to="/home" active-class="active">Home</router-link>
                    </li>
                    <li @click="clicked">
                        <router-link to="/our-story" active-class="active">Our Story</router-link>
                    </li>
                    <li @click="clicked">
                        <router-link to="/products" active-class="active">Products</router-link>
                    </li>
                    <li @click="clicked">
                        <router-link to="/why-organic" active-class="active">Why Suvidi</router-link>
                    </li>
                    <li @click="clicked">
                        <router-link to="/farmer-stories" active-class="active">Farmer Stories</router-link>
                    </li>
                    <li @click="clicked">
                        <router-link to="/our-stores" active-class="active">Our Shop</router-link>
                    </li>
                    <li @click="clicked">
                        <router-link to="/contact" active-class="active">Contact Us</router-link>
                    </li>
                    <li v-if="currentUser.username && currentUser.superuser === false" @click="clicked">
                        <router-link to="/myprofile" active-class="active">My Profile</router-link>
                    </li>
                    <li v-if="currentUser.username && currentUser.superuser === false" @click="clicked">
                        <router-link to="/myorders" active-class="active">My Orders</router-link>
                    </li>
                    <li @click="clicked" v-if="currentUser.username && currentUser.superuser === false">
                        <a @click="logOut">Logout</a>
                    </li>
                    <li @click="clicked" v-else>
                        <a v-b-modal.modal-center>Log In</a>
                    </li>
                    <li @click="clicked">
                        <router-link to="/Cart" class="cart-menu" active-class="active"><img
                            src="../assets/images/shopping-cart.png" class="img-fluid mr-3" alt="#">Cart
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'Sidenav',
    methods: {
        clicked () {
            this.$emit('Clicked');
        },
        ...mapActions(['signOut']),
        logOut () {
            this.signOut();
            axios.get(urls.auth.logout);
            this.$router.push('/');
        }
    },
    computed: {
        ...mapGetters(['currentUser'])
    }
};
</script>

<style scoped>

</style>
